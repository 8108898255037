<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="推荐排名" left-arrow></nav-bar>
    </template>
    <scroll-view
      height="fullView"
      ref="scroll"
      pullup
      @scrollToEnd="loadMore()"
    >
      <div class="recommend-box">
        <div class="recommend-header ds-flex">
          <div class="recommend-title">用户推荐</div>
          <div class="recommend-rightside ds-flex">
            <i class="iconfont2 icon--rili calc-icon"></i>
            <span>近30天推荐数据</span>
          </div>
        </div>
        <!-- 总觉数据部分 -->
        <div class="recommend-alldata">
          <div class="recommend-alldata__box">
            <span class="font-green font-fivefour">{{
              dashbordData.recommandMemberCount || "0"
            }}</span>
            <div class="tag">
              推荐人<i class="iconfont2 icon-lihai-E green-icon"></i>
            </div>
          </div>
          <div class="recommend-alldata__box">
            <span class="font-yellow font-fivefour">{{
              dashbordData.newWeChatCardCount || "0"
            }}</span>
            <div class="tag">
              新办卡<i class="iconfont2 icon-huiyuan yellow-icon"></i>
            </div>
          </div>
          <i class="iconfont2 icon--leftarrow leftarrow"></i>
          <div class="recommend-alldata__box">
            <div class="font-red font-fivefour">
              <span class="danwei">¥</span>{{
                dashbordData.saleAmount ? `${dashbordData.saleAmount}` : "0"
              }}
            </div>
            <div class="tag">总消费</div>
          </div>
        </div>
        <div class="recommend-tips">
          <div class="recommend-people">推荐人</div>
          <div class="recomend-num">领卡会员</div>
          <div class="recommend-spendmoney">消费金额</div>
        </div>
        <!-- 列表部分 -->
        <div class="recommend-content">
          <ul>
            <li
              v-for="(item, index) in memberList"
              :key="index"
              @click="jumpToDetail(item)"
              class="van-hairline--bottom list-item"
            >
              <div class="people__info info-box">
                <div class="info__avatar">
                  <img
                    v-if="item.portrait"
                    :src="imgFullFix(item.portrait, 'small')"
                    alt=""
                  />
                  <div v-else class="info__avatar--name van-hairline--surround">
                    {{ item.memberName && item.memberName.substr(0, 1) }}
                  </div>
                </div>
                <div class="info__other">
                  <div class="info__name text-elps">{{ item.memberName }}</div>
                  <div class="info__remark text-elps">{{ item.phone }}</div>
                </div>
              </div>
              <div class="people__sex">{{ item.recommendCt }}</div>
              <div class="people__sale-money">
                <!-- <span v-if="item.recommandSaleAmount">￥{{ item.recommandSaleAmount }}</span>
                <span v-else>￥0</span> -->
                <price
                  :price="item.recommandSaleAmount"
                  :needSymbol="true"
                  :thousands="false"
                  class="money"
                />
                <i class="van-icon van-icon-arrow-up more-icon"></i>
              </div>
            </li>
          </ul>
          <div
            v-if="noMore"
            style="width: 60%; margin: 15px auto 40px auto"
            class="van-divider van-divider--hairline van-divider--content-center"
          >
            没有更多了
          </div>
          <div
            v-if="empty"
            class="ds-flex align-center"
            style="
              justify-content: center;
              width: 100%;
              mini-height: 100px;
              color: #999;
            "
          >
            暂无数据
          </div>
          <div v-if="loadMoreModel" class="loading-more text-center">
            正在加载中，请稍后...
          </div>
        </div>
      </div>
    </scroll-view>
  </page-view>
</template>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<script>
import PageView from '@/layouts/PageView'
import ScrollView from '@/components/scroll-view'
import NavBar from '@/components/nav-bar'
import Price from '@/components/price'
import { imgFullFix } from '@/utils/img'
import WechatCardModel from '@/model/wechat-card/index'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      loading: false, // 加载中
      loaded: false, // 加载完成
      empty: false,
      loadMoreModel: false,
      noMore: false,
      pageConfig: {
        pageIndex: 1,
        pageSize: 15,
        totalCounts: 0
      },
      searchObj: {
        beginDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
        // beginDate: '2020-01-01',
        endDate: dayjs().format('YYYY-MM-DD')
      },
      memberList: [],
      dashbordData: {
        recommandMemberCount: 0,
        newWeChatCardCount: 0,
        saleAmount: 0
      }
    }
  },
  components: { PageView, ScrollView, NavBar, Price },
  created () {
  },
  mounted () {
    this.initDashbord()
    this.loadMemberList(true)
  },
  beforeDestroy () {
  },
  methods: {
    imgFullFix,
    async initDashbord () {
      const { beginDate, endDate } = this.searchObj
      const json = await WechatCardModel.getRecommandMemberDashboard(beginDate, endDate)
      if (json.result) {
        this.dashbordData = json.data
      }
    },

    // 加载更多数据
    async loadMore () {
      if (this.noMore) return
      this.loading = true
      this.loadMoreModel = true
      await this.loadMemberList()
      this.loading = false
      // this.reastMin()
    },

    async loadMemberList (isNewPage = false) {
      if (isNewPage) {
        this.memberList = []
        this.pageConfig.pageIndex = 1
        this.pageConfig.totalCount = 0
        this.noMore = false
        this.empty = false
        this.loadMoreModel = true
      }
      const { beginDate, endDate } = this.searchObj
      const start = this.pageConfig.pageIndex
      const limit = this.pageConfig.pageSize
      const { code, data } = await WechatCardModel.getRecommandMemberList(beginDate, endDate, start, limit)
      if (code === 200) {
        this.loadMoreModel = false
        this.memberList = this.memberList.concat(data.items)
        this.pageConfig.pageIndex += 1
        this.pageConfig.totalCount = data.totalCounts
        this.noMore = data.items.length < this.pageConfig.pageSize
        this.empty = (this.pageConfig.pageIndex === 1 && !data.items.length)
      } else {
        throw Error('加载推荐用户列表失败')
      }
      // 数据加载完成之后要进行重新计算当前宽度
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    },
    jumpToDetail (value) {
      this.$router.push({
        path: '/recommend/detail',
        query: {
          id: value.openid,
          name: value.memberName,
          portrait: value.headImg,
          recommandSaleAmount: value.orderAmount,
          memberNo: value.memberNo,
          recommandCount: value.recommendCt / 10000
        }
      })
    }
  },
  computed: {
  },
  watch: {}
}
</script>
